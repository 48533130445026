import { extendTheme } from '@chakra-ui/react'
import { ButtonConfig } from './components/Button/Button'
import { HeadingConfig } from './components/Heading/Heading'

const theme = extendTheme({
  fonts: {
    heading: `'Montserrat', sans-serif`,
    body: `'Montserrat', sans-serif`,
  },
  colors: {
    brand: {
      500: '#18191F',
    },
  },
  components: {
    Button: ButtonConfig,
    Heading: HeadingConfig,
  },
})

export default theme
