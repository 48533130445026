import { ChakraProvider, useMediaQuery } from '@chakra-ui/react'
import { createBrowserSupabaseClient } from '@supabase/auth-helpers-nextjs'
import { Session, SessionContextProvider } from '@supabase/auth-helpers-react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import DesktopMessage from 'components/DesktopMessage'
import { useInitMusicKit } from 'hooks/useInitMusicKit'
import type { AppProps } from 'next/app'
import { Router } from 'next/router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { useState } from 'react'
import MobileFrame from '../components/MobileFrame/MobileFrame'
import '../styles/globals.css'
import theme from '../theme'

NProgress.configure({ showSpinner: false })
Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

const queryClient = new QueryClient()

function MyApp({
  Component,
  pageProps,
}: AppProps<{
  initialSession: Session
}>) {
  useInitMusicKit()
  const [supabaseClient] = useState(() => createBrowserSupabaseClient())
  const [isMobileDevice] = useMediaQuery('(max-width: 500px)')

  return (
    <SessionContextProvider
      supabaseClient={supabaseClient}
      initialSession={pageProps.initialSession}
    >
      <QueryClientProvider client={queryClient}>
        <ChakraProvider theme={theme}>
          {isMobileDevice ? (
            <MobileFrame>
              <Component {...pageProps} />
            </MobileFrame>
          ) : (
            <DesktopMessage />
          )}
        </ChakraProvider>
      </QueryClientProvider>
    </SessionContextProvider>
  )
}

export default MyApp
