import { useEffect } from 'react'

export const useInitMusicKit = () => {
  useEffect(() => {
    function setup() {
      window.MusicKit.configure({
        developerToken: process.env.NEXT_PUBLIC_MUSICKIT_TOKEN,
        app: {
          name: 'Radio Remember',
          build: '1.0.0',
        },
      })
    }

    if (window.MusicKit !== undefined) {
      setup()
    } else {
      document.addEventListener('musickitloaded', setup)
    }
  }, [])
}
