import React, { FC, memo } from 'react'
import { Box, Flex, Heading, Text } from '@chakra-ui/react'

interface Props {}

const DesktopMessage: FC<Props> = (props) => {
  return (
    <Flex alignItems="center" flexDirection="column" minHeight="100vh" justifyContent="center">
      <Box bg="gray.300" p={20} px={28} borderRadius="xl" border={2} borderStyle="solid"></Box>
      <Text mt={12} fontSize="xl" color="gray.500">
        Desktop experience coming soon
      </Text>
      <Heading mt={2}>Please use your mobile</Heading>
    </Flex>
  )
}

export default DesktopMessage
