import { Container, Flex, Stack } from '@chakra-ui/react'
import React, { PropsWithChildren } from 'react'

interface Props extends PropsWithChildren {}

const MobileFrame: React.FC<Props> = ({ children }) => {
  return (
    <div className="flex items-center justify-center">
      <div className="w-[420px] p-4">{children}</div>
    </div>
  )
}

export default MobileFrame
